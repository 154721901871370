import Flickity from 'flickity'
import "flickity-imagesloaded"

// Get all flickity dom elements
//
const flickities = function() {
  return document.querySelectorAll('[data-flickity]');
}

// Explicitly initialize Flickity on Turbolinks events
//
// The Flickity auto initializer only hits document:ready
//
document.addEventListener("turbolinks:load", () => {
  for (const el of flickities()) {
    const options = JSON.parse(el.dataset.flickity)
    new Flickity(el, options)
  }
})

// Explicitly tear down flickity when navigating away
//
// Without this, Flickity won't to re-initialize an 
//   element it still thinks exists.
//
document.addEventListener("turbolinks:before-cache", () => {
  for (const el of flickities()){
    Flickity.data(el).destroy();
  }
})
