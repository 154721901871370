import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "source" ]

  toggle() {
    this.sourceTargets.forEach(el => {
      el.classList.toggle('is-active');
    });
  }
}